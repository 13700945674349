<template>
  <div class="drawn-number"
       :class="{'retail' : isRetail}"
       :style="getStyle">
    <div class="value"
         v-if="!inProgress">
      {{value}}
    </div>
    <DrawnNumberSpinner spinner-color="#fff"
                        bg-color="#000"
                        :show="inProgress"/>
  </div>
</template>

<script>
import DrawnNumberSpinner from '@/components/DrawnNumberSpinner';

export default {
  name: 'DrawnNumber',
  components: { DrawnNumberSpinner },
  props: {
    value: {
      type: [String, Number],
    },
    type: {
      type: String, // bonus or regular
      default: 'regular',
    },
    inProgress: {
      type: Boolean,
      required: true,
    },
    isRetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getStyle() {
      const style = {
        backgroundColor: this.inProgress && !this.value ? 'transparent' : '#000',
        color: '#000',
        border: `1px solid ${this.type === 'bonus'
        && this.inProgress === false ? '#FFD837' : 'transparent'}`,
        opacity: this.value ? 1 : 0.6,
      };
      if (this.value) {
        style.backgroundColor = this.type === 'bonus' ? '#ffd837' : '#fff';
        style.borderColor = this.type === 'bonus' ? '#ffd837' : 'rgba(0, 0, 0, .6)';
      }
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
  .drawn-number {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: 12px;
    height: 26px;
    justify-content: center;
    position: relative;
    width: 26px;
    @media screen and (min-width: 730px){
      font-size: 14px;
      height: 36px;
      width: 36px;
    }
    &.retail {
      font-size: 22px;
      height: 52px;
      width: 52px;
      @media (min-width: 1920px) {
        font-size: 30px;
        height: 78px;
        width: 78px;
      }
    }
  }
</style>
