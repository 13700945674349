<template>
  <transition name="fade">
    <div class="spinner"
         v-if="show"
         :style="{
    background: show ? `conic-gradient(${spinnerColor}, ${spinnerColor},
    transparent, transparent)` : 'transparent'}">
      <div class="spinner-background"
           :style="{backgroundColor: bgColor}">
      </div>
      <div class="spinner-round-start"
           :style="{backgroundColor: show ? spinnerColor : 'transparent'}">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DrawnNumberSpinner',
  props: {
    spinnerColor: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  animation: rotate 2s infinite ease-in-out;
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 100%;
  .spinner-background,
  .spinner-round-start {
    position: absolute;
  }
  .spinner-background {
    border-radius: 50%;
    height: 94%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 94%;
  }

  .spinner-round-start {
    border-radius: 50%;
    height: 3%;
    width: 3%;
    top:0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@keyframes rotate {
  0% {
    transform: rotateZ(110deg);
  }
  100% {
    transform: rotateZ(-250deg);
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
