<template>
  <div class="drawn-numbers"
       :class="{'retail' : isRetail}">
    <DrawnNumber v-for="(num, index) in max"
                 :key="index"
                 :value="numbers[index] ? numbers[index] : null"
                 :in-progress="isInProgress(index)"
                 :is-retail="isRetail"
                 :type="type"/>
  </div>
</template>

<script>
import DrawnNumber from '@/components/DrawnNumber';

export default {
  name: 'DrawnNumbers',
  components: { DrawnNumber },
  props: {
    drawStarting: {
      type: Boolean,
    },
    numbers: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    type: {
      type: String, // bonus or regular
      default: 'regular',
    },
    regularDrawnFinished: {
      type: Boolean,
      default: false,
    },
    isRetail: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isInProgress(index) {
      // console.log('---------- isInProgress start----------------------------------------------');
      // console.log('numbers length', this.numbers.length);
      // console.log('value in index numbers', this.numbers[index]);
      // console.log('index', index, this.max);
      // console.log('this.numbers.length - 1 === index', this.numbers.length - 1 === index);
      // console.log('max', this.max);
      // console.log('isInProgress numbers', this.numbers);
      let status = false;
      if (this.numbers.length) {
        status = this.numbers.length + 1 <= this.max
          && this.numbers.length === index
          && (this.type === 'bonus' ? this.regularDrawnFinished : true);
        // status = this.numbers.length + 1 <= this.max
        //   && !!(this.numbers[index] === 0 || this.numbers[index])
        //   && this.numbers.length - 1 === index
        //   && (this.type === 'bonus' ? this.regularDrawnFinished : true);
      } else if (this.drawStarting && index === 0) {
        status = true;
      }
      // console.log('status', status);
      // console.log('---------- isInProgress end------------------------------------------------');
      return status;
    },
  },
};
</script>

<style lang="scss" scoped>
 .drawn-numbers {
   display: grid;
   grid-template-columns: repeat(4,1fr);
   grid-gap: 7px;
   @media screen and (max-width: 729px){
     grid-template-columns: repeat(10,1fr);
   }
   &.retail {
     grid-template-columns: repeat(10,1fr);
     grid-gap: 12px;
     @media (min-width: 1920px) {
       grid-gap: 20px;
     }
   }
 }
</style>
