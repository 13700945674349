<template>
  <div class="last-results-view"
       v-if="retailNextEvent">
    <SidebarTitle :title="translate('latestResults')"
                  :subtitle="translate('drawnAt', getTime)" />
    <FlagCategoryTournament class="last-results-flag-wrapper"
                            :event="retailNextEvent"/>
    <DrawnNumbers :numbers="results"
                  :is-retail="true"
                  :draw-starting="false"
                  :max="20"/>
    <p>{{ translate('additionalBetting') }}</p>
    <div class="results-specials">
      <section class="result-special"
               v-for="(special, key) in resultsSpecials"
               :key="key"
               :class="key">
        <div class="result-special-outcome">
          <span>{{ translate(key) }}</span>
          <span v-if="key === 'drawSumTotal'">
            {{ getmarket.outcomes[0].name.replace(/[^\d.-]/g,'') }}
          </span>
        </div>
        <div class="result-special-value">{{ special }}</div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarTitle from '@/components/Retail/SidebarTitle';
import { mapGetters } from 'vuex';
import { formatDate, searchMarket } from '@/utility';
import FlagCategoryTournament from '@/components/Retail/FlagCategoryTournament';
import DrawnNumbers from '@/components/DrawnNumbers';
import switchComponent from '@/utility/switchComponent';

export default {
  name: 'LastResultsView',
  components: { DrawnNumbers, FlagCategoryTournament, SidebarTitle },
  computed: {
    ...mapGetters({
      results: 'results',
      translate: 'translate',
      resultsSpecials: 'resultsSpecials',
      eventTime: 'eventTime',
      timeZone: 'timeZone',
      retailNextEvent: 'retailNextEvent',
      screenSize: 'screenSize',
    }),
    getmarket() {
      return searchMarket(this.retailNextEvent.markets, '822');
    },
    getFlagSize() {
      return {
        width: (this.screenSize === 1920 ? '105px' : '70px'),
        height: (this.screenSize === 1920 ? '60px' : '40px'),
      };
    },
  },
  methods: {
    getTime() {
      return formatDate(this.eventTime, 'HH:mm', this.timeZone);
    },
    closeComponent() {
      switchComponent(
        'right',
        3,
        this.$store.getters.transitionBetweenComponentDuration,
      );
    },
  },
  mounted() {
    this.closeComponent();
  },
};
</script>

<style lang="scss" scoped>
 .last-results-view {
   align-items: center;
   display: flex;
   flex-direction: column;
   height: 100%;
   .last-results-flag-wrapper {
     margin-top: 38px;
     @media (min-width: 1920px) {
       margin-top: 59px;
     }
   }
   .drawn-numbers {
     margin: 26px 0;
     grid-template-columns: repeat(5, 1fr) !important;
   }
   > p {
     color: #EFEFEF;
     font-size: 24px;
     margin-bottom: 15px;
     @media (min-width: 1920px) {
       font-size: 36px;
       margin-bottom: 24px;
     }
   }
   .results-specials {
     display: flex;
     flex-direction: column;
     text-align: center;
     .drawSumEvenOdd {
       order: 1;
     }
     .drawSumTotal {
       order: 2;
     }
     .drawSumRange {
       order: 3;
     }
     .result-special-outcome {
       color: #A3A3A3;
       font-size: 14px;
       line-height: 13px;
       opacity: .88;
       @media (min-width: 1920px) {
         font-size: 20px;
         line-height: 20px;
       }
     }
     .result-special-value {
       color: #fff;
       font-size: 18px;
       line-height: 20px;
       margin-bottom: 15px;
       opacity: .88;
       text-transform: uppercase;
       @media (min-width: 1920px) {
         font-size: 28px;
         line-height: 30px;
       }
     }
   }
 }
</style>
